<template>
  <div>
    <div class="mb-10 font-weight-bold text-dark">Determine the specifications of the sunbeds</div>

    <div class="row">
      <div class="col-xl-6">
        <div class="form-group">
          <label>Model <sup>Required</sup></label>
          <select
            :class="['form-control', { 'is-invalid': $v.document.model.$error }]"
            v-model.trim="$v.document.model.$model"
            :disabled="isDisabled"
            :readonly="isReadOnly"
          >
            <option :value="null" disabled>No model selected</option>

            <option v-for="(model) in sunbeds.models" :key="`sunbed.${model.id}`" :value="model.id">
              {{ model.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="form-group">
          <label>Type <sup>Required</sup></label>
          <select
            :class="['form-control', { 'is-invalid': $v.document.type.$error }]"
            v-model.trim="$v.document.type.$model"
            :disabled="(isDisabled || !document.model)"
            :readonly="isReadOnly"
          >
            <template v-if="!document.model">
              <option :value="null" disabled>Select a model first</option>
            </template>

            <template v-else>
              <option :value="null" disabled>No type selected</option>

              <option v-for="(type) in equipmentModel.types" :key="`sunbed.${document.model}.${type.id}`" :value="type.id">
                {{ type.name }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <!-- Specifications -->
    <template v-if="equipmentModel && equipmentType">
      <!-- Specification Colors -->
      <div
        v-if="equipmentType && equipmentType.colors && equipmentType.colors.length"
        class="row border-top pt-3"
      >
        <div
          v-for="(color) in equipmentType.colors"
          :key="`sunbed.${document.model}.${document.type}.color.${color.id}`"
          class="col-12 col-lg"
        >
          <div class="form-group">
            <label>{{ color.name }} <sup>Required</sup></label>
            <select
              :value="document.getColorOfLocation(color.id) || null"
              :class="['form-control', { 'is-invalid': $v.document.specifications.colors.$error }]"
              :disabled="(isDisabled || !document.model)"
              :readonly="isReadOnly"
              @change.prevent="onColorChange(color.id, $event.target.value)"
            >
              <option :value="null" disabled>No color selected</option>

              <option v-for="(option) in color.options" :key="`sunbed.${document.model}.${document.type}.color.${color.id}.${option}`" :value="option">{{ option }}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Specification Filters -->
      <div
        v-if="equipmentModel && equipmentModel.filters && equipmentModel.filters.length"
        class="row border-top pt-3"
      >
        <div class="col-12 col-lg">
          <div class="form-group">
            <label>Filterglass <sup>Required</sup></label>
            <div class="row">
              <div
                v-for="(filter) in equipmentModel.filters"
                :key="`sunbed.${document.model}.${document.type}.filter.${filter}`"
                class="col-12 col-md"
              >
                <label :class="['option cursor-pointer', { 'border-danger': $v.equipmentFilterglass.$error }]">
                  <span class="option-control">
                    <span class="radio">
                      <input
                        type="radio"
                        name="sunbed.filter"
                        :value="filter"
                        :disabled="isDisabled || !document.model || isReadOnly"
                        v-model.trim="equipmentFilterglass"
                      >
                      <span />
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-title">{{ $tu(`sunbeds.models.${document.model}.filters.${filter}`) || filter }}</span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Specification Filters -->

      <!-- Specification Options -->
      <div v-if="equipmentModel && equipmentModel.options && equipmentModel.options.length" class="row border-top pt-3">
        <div class="col-12 mb-5">Sunbed options</div>

        <div class="col-12">
          <div class="row row-cols-1 row-cols-lg-2">
            <div
              v-for="(option) in equipmentModel.options"
              :key="`sunbed.${document.model}.option.${option}`"
              class="col"
            >
              <div class="form-group row">
                <label class="col-9 col-form-label">{{ $tu(`sunbeds.models.${document.model}.options.${option}`) || option }}</label>
                <div class="col">
                  <span :class="['switch float-right', { 'switch-success': document.specifications.options.indexOf(option) !== -1 }]">
                    <label>
                      <input
                        type="checkbox"
                        checked
                        :value="option"
                        :disabled="isDisabled"
                        :readonly="isReadOnly"
                        v-model.trim="document.specifications.options"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import Form from '@/mixins/form';
import Equipment from '@/libs/classes/equipment';
import Sunbeds from '@/components/pages/sunbeds/libs/sunbeds';

export default {
  mixins: [Form],
  props: {
    document: {
      type: Equipment,
      default: null,
    },
  },
  computed: {
    equipmentModel () {
      if (!this.document.model || !this.sunbeds) {
        return null;
      }

      return this.sunbeds.models.find((m) => m.id === this.document.model);
    },
    equipmentType () {
      if (!this.equipmentModel || !this.equipmentModel.types || !this.document.type) {
        return null;
      }

      return this.equipmentModel.types.find((t) => t.id === this.document.type);
    },
    equipmentFilterglass: {
      get () {
        if (!this.equipmentModel || !this.equipmentModel.filters) {
          return undefined;
        }

        return this.document.specifications.options.find((option) => this.equipmentModel.filters.includes(option));
      },

      set (value) {
        if (!this.equipmentModel || !this.equipmentModel.filters) {
          return undefined;
        }

        const documentOptions = [...this.document.specifications.options].filter((option) => this.equipmentModel.filters.includes(option) === false, []);
        this.$set(this.document.specifications, 'options', [...documentOptions, value]);
        return value;
      },
    },
  },
  watch: {
    'document.model': {
      handler () {
        this.$v.document.type.$model = null;
        this.$v.document.type.$reset();
        this.$v.equipmentFilterglass.$reset();
      },
    },
    'document.type': {
      handler () {
        // Clear colors and options
        this.document.specifications.colors = [];
        this.document.specifications.options = [];

        if (this.equipmentType) {
          // Set colors, if defined for type
          if (this.equipmentType.colors) {
            this.document.specifications.colors = this.equipmentType.colors.map((color) => { // eslint-disable-line
              return {
                location: color.id,

                // If only one option is found, set that option by default
                color: (color.options && color.options.length === 1 ? color.options[0] : null),
              };
            });
          }

          // Set default options, if defined for type
          if (this.equipmentType.options) {
            this.document.specifications.options = [...this.equipmentType.options];
          }

          // Set default filter, if defined for type
          if (this.equipmentType.filter) {
            this.document.specifications.options.push(this.equipmentType.filter);
          }
        }
      },
    },
  },

  data () {
    return {
      sunbeds: Sunbeds.sunbeds,
    };
  },

  validations: {
    document: {
      model: {
        required,
      },
      type: {
        required,
      },

      specifications: {
        colors: {
          required (value) {
            // Don't validate if no colors are found
            if (!this.equipmentType.colors || this.equipmentType.colors.length === 0) {
              return true;
            }

            // Check if has value for every color
            // eslint-disable-next-line arrow-body-style
            if (this.equipmentType.colors.every((color) => {
              // eslint-disable-next-line arrow-body-style
              return !!(value || []).find((valueColor) => {
                // Check if every color is found and has a value
                return valueColor.location === color.id && typeof valueColor.color === 'string' && valueColor.color.length > 0;
              });
            })) {
              return true;
            }

            return false;
          },
        },
      },
    },

    // Filterglass validation
    equipmentFilterglass: {
      // eslint-disable-next-line func-names
      requiredIf: requiredIf(function () {
        return (this.equipmentModel && this.equipmentModel.filters && this.equipmentModel.filters.length > 0);
      }),
    },
  },

  methods: {
    onColorChange (location, value) {
      const equipmentColorIdx = (this.document.specifications.colors || []).findIndex((color) => color.location === location);
      if (equipmentColorIdx !== -1) {
        this.$set(this.document.specifications.colors[equipmentColorIdx], 'color', value);
      } else {
        this.document.specifications.colors.push({ location, color: value });
      }
    },
  },
};
</script>
