<template>
  <div class="row">
    <!-- begin::Overview -->
    <div class="col-12">
      <KTWizard ref="wizard" v-model="wizardIndex">
        <KTWizardNav>
          <KTWizardStep>
            <div class="wizard-title">
              Sunbed serial
            </div>
          </KTWizardStep>

          <KTWizardStep>
            <div class="wizard-title">
              Sunbed specifications
            </div>
          </KTWizardStep>
        </KTWizardNav>

        <KTWizardBody>
          <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-7">
              <!-- begin::Wizard Form-->
              <form class="form mt-0 mt-lg-10">
                <!-- begin::Step 1 -->
                <KTWizardStepContent>
                  <serial-form
                    ref="serialForm"

                    :document="equipment"

                    action="CREATE"
                    :disabled="isLoadingForm"
                  />
                </KTWizardStepContent>
                <!-- end::Step 1 -->

                <!-- begin::Step 2 -->
                <KTWizardStepContent>
                  <sunbed-form
                    ref="sunbedForm"

                    :document="equipment"

                    action="CREATE"
                    :disabled="isLoadingForm"
                  />
                </KTWizardStepContent>
                <!-- end::Step 2 -->

                <!--begin: Wizard Actions -->
                <div v-if="isMounted" class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      v-if="(wizardIndex > 0)"
                      key="wizardPreviousStep"
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      :disabled="isLoadingForm"
                      @click.prevent="$refs.wizard.previous()"
                    >
                      Previous
                    </button>
                  </div>
                  <div>
                    <button
                      v-if="(wizardIndex === this.$refs.wizard.steps.length - 1)"
                      key="wizardSubmit"
                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                      :disabled="isLoadingForm"
                      @click.prevent="submitForm"
                    >
                      Submit
                    </button>
                    <button
                      v-else
                      key="wizardNextStep"
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      :disabled="isLoadingForm"
                      @click.prevent="$refs.wizard.next()"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
              <!-- end::Wizard Form -->
            </div>
          </div>
        </KTWizardBody>
      </KTWizard>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/layout/variables.scss";
@import "@vedicium/metronic-vue/src/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { Mixins } from '@vedicium/metronic-vue';
import { validations } from '@vedicium/core-vue';
import Equipment from '@/libs/classes/equipment';

// Components
import serialForm from '@/components/pages/sunbeds/components/serial.form.vue';
import sunbedForm from '@/components/pages/sunbeds/components/sunbed.form.vue';

export default {
  mixins: [Mixins.KTWizardV4],
  components: {
    serialForm,
    sunbedForm,
  },
  data () {
    return {
      isMounted: false,
      isLoadingForm: false,

      wizardIndex: 0,

      equipment: new Equipment().merge({ app: 'myluxura' }),
    };
  },
  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Sunbeds' }, { title: 'Add sunbed' }]);

    this.$nextTick(() => {
      this.isMounted = true;

      this.$refs.wizard.$on('step-change', this.onWizardStepChange.bind(this));
    });
  },
  beforeDestroy () {
    this.$refs.wizard.$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async onWizardStepChange (step, oldStep) {
      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          this.$refs.wizard.setStep(oldStep);
        }
      }
    },

    async validate (step = -1) {
      const validationArray = [];

      // Add validators based by step
      if (step === 0 || step === -1) validationArray.push(this.$refs.serialForm.$v);
      if (step === 1 || step === -1) validationArray.push(this.$refs.sunbedForm.$v);

      await validations.validateArray(validationArray);
    },

    async submitForm () {
      this.$errors.clear();
      this.$set(this, 'isLoadingForm', true);

      let equipment = null;
      try {
        await this.validate();
        equipment = await Equipment.create(this.equipment.formatBody('create'));
      } catch (e) {
        this.$set(this, 'isLoadingForm', false);
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      }

      this.$router.push(`/sunbeds/view/${equipment._meta.guid}/settings/module?link-module`);
    },
  },
};
</script>
