<template>
  <div>
    <div class="mb-10 font-weight-bold text-dark">Enter the sunbed's serialnumber(s)</div>

    <div class="form-group">
      <label>Sunbed serialnumber <sup>Required</sup></label>
      <input
        type="text"
        :class="['form-control form-control-lg', { 'is-invalid': $v.document.serial.$error }]"
        :disabled="isDisabled"
        :readonly="isReadOnly"

        v-model.trim="$v.document.serial.$model"
      >
    </div>

    <div class="form-group">
      <label>Submanufacturer serialnumber</label>
      <input
        type="text"
        :class="['form-control form-control-lg', { 'is-invalid': $v.document.serial_submanufacturer.$error }]"
        :disabled="isDisabled"
        :readonly="isReadOnly"

        v-model.trim="$v.document.serial_submanufacturer.$model"
      >
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { isSunbedSerial } from '@/libs/validators';
import Form from '@/mixins/form';
import Equipment from '@/libs/classes/equipment';

export default {
  mixins: [Form],
  props: {
    document: {
      type: Equipment,
      default: null,
    },
  },

  validations: {
    document: {
      serial: {
        required,
        serial: isSunbedSerial,
      },
      serial_submanufacturer: {},
    },
  },
};
</script>
